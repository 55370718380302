body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* These styles are used if a demo specific stylesheet is not present */

.AppWrapper *,
.AppWrapper *:before,
.AppWrapper *:after {
  box-sizing: border-box;
}

.AppWrapper body,
.AppWrapper html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
}

.AppWrapper form {
  max-width: 800px;
  margin: 80px auto;
}

.AppWrapper label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
}

.AppWrapper button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow:
    0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.AppWrapper button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}

.AppWrapper button[disabled] {
  opacity: 0.6;
}

.AppWrapper input,
.AppWrapper select {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

.AppWrapper input::placeholder {
  color: #aab7c4;
}

.AppWrapper .result,
.AppWrapper .error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.AppWrapper .error {
  color: #e4584c;
}

.AppWrapper .result {
  color: #666ee8;
}

/*
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.AppWrapper .StripeElement,
.AppWrapper .StripeElementIdeal,
.AppWrapper .StripeElementP24,
.AppWrapper .StripeElementEPS,
.AppWrapper .StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.AppWrapper .StripeElement--focus,
.AppWrapper .StripeElementIdeal--focus,
.AppWrapper .StripeElementP24--focus,
.AppWrapper .StripeElementEPS--focus,
.AppWrapper .StripeElementFpx--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.AppWrapper .StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.AppWrapper .StripeElementIdeal,
.AppWrapper .StripeElementP24,
.AppWrapper .StripeElementEPS,
.AppWrapper .StripeElementFpx {
  padding: 0;
}

* {
  box-sizing: border-box;
}

.AppWrapper input,
.AppWrapper button {
  all: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.AppWrapper {
  width: 500px;
  height: 400px;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.AppWrapper .Form {
  animation: fade 200ms ease-out;
}

.AppWrapper .FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.AppWrapper .FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.AppWrapper .FormRow:first-child {
  border-top: none;
}

.AppWrapper .FormRowLabel {
  all: unset;
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #c4f0ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.AppWrapper .FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.AppWrapper .FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.AppWrapper .FormRowInput::placeholder {
  color: #87bbfd;
}

.AppWrapper .StripeElement--webkit-autofill {
  background: transparent !important;
}

.AppWrapper .StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
  margin: 0;
  background: none;
}

.AppWrapper .SubmitButton {
  text-align: center;
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.AppWrapper .SubmitButton:active {
  background-color: #d782d9;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.AppWrapper .SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.AppWrapper .SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.AppWrapper .SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}

.AppWrapper .ErrorMessage {
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.AppWrapper .ErrorMessage svg {
  margin-right: 10px;
}

.AppWrapper .Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.AppWrapper .ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.AppWrapper .ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.AppWrapper .ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
